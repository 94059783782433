/** @jsx jsx */
import { jsx, Flex, Box, Button, Divider, Message, Select, Heading, Text, Link as ThemeLink, Grid } from 'theme-ui'
import { Fragment } from 'react'
import { useLocation } from '@reach/router'
import { MDXProvider } from '@mdx-js/react'
import { Helmet } from 'react-helmet'
import useSiteMetadata from '../hooks/use-site-metadata'
import Header from './header'
// import Blob from './blob'
import Footer from './footer'
import favicon from '../images/favicon.ico'

const Layout = ({ children }) => {
  const { pathname } = useLocation()
  const shortcodes = {
    Divider,
    Message,
    Select,
    Heading,
    Text,
    ThemeLink,
    Flex,
    Box,
    Button,
    Grid,
  }

  const { menuLinks } = useSiteMetadata()

  return (
    <Fragment>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <MDXProvider components={shortcodes}>
        <Fragment>
          <div
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
              position: 'relative',
            }}
          >
            <Header location={pathname} navLinks={menuLinks} />
            {/* <Blob /> */}

            <main
              sx={{
                width: '100%',
                flex: '1 1 auto',
                bg: 'inherit',
                zIndex: '70',
                overflow: 'hidden',
              }}
            >
              {children}
              {/* <Container>{children}</Container> */}
            </main>
            <Footer />
          </div>
        </Fragment>
      </MDXProvider>
    </Fragment>
  )
}

export default Layout
