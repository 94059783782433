/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'

const BurgerButton = ({ isOpen, onClickHandler }) => {
  return (
    <Flex
      tabindex="0"
      onClick={() => onClickHandler()}
      sx={{
        mx: 3,
        width: '2rem',
        height: '2rem',
        // position: 'fixed',
        // top: '15px',
        // right: '20px',
        // zIndex: '20',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-around',
        alignItems: 'flex-end',
      }}
    >
      <div
        sx={{
          width: isOpen ? '2rem' : '1.65rem',
          height: '0.25rem',
          bg: isOpen ? 'burgerIconActive' : 'burgerIcon',
          borderRadius: '10px',
          transformOrigin: '1px',
          transition: 'all 0.3s linear',
          transform: isOpen ? 'rotate(45deg)' : 'rotate(0)',
        }}
      />
      <div
        sx={{
          width: '2rem',
          height: '0.25rem',
          bg: isOpen ? 'burgerIconActive' : 'burgerIcon',
          borderRadius: '10px',
          transition: 'all 0.3s linear',
          transform: isOpen ? 'translateX(100%)' : 'translateX(0)',
          opacity: isOpen ? 0 : 1,
        }}
      />
      <div
        sx={{
          width: isOpen ? '2rem' : '1.3rem',
          height: '0.25rem',
          bg: isOpen ? 'burgerIconActive' : 'burgerIcon',
          borderRadius: '10px',
          transformOrigin: '1px',
          transition: 'all 0.3s linear',
          transform: isOpen ? 'rotate(-45deg)' : 'rotate(0)',
        }}
      />
    </Flex>
  )
}

export default BurgerButton
