/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useState } from 'react'
import { animated, useSpring } from 'react-spring'

function TwitterIcon({ width, height, footer }) {
  const [active, setActive] = useState({ hovered: false, duration: 4000 })

  const fromColorFill = footer ? '#ffffff' : '#1da1f2'
  const toColorFill = footer ? '#1da1f2' : '#be4b52'

  const props = useSpring({
    from: active.hovered ? { fill: fromColorFill, transform: 'rotate(0deg)' } : { fill: fromColorFill, transform: 'rotate(0deg)' },
    to: active.hovered ? { fill: toColorFill, transform: 'rotate(25deg)' } : { fill: fromColorFill, transform: 'rotate(0deg)' },
    config: active.hovered ? { duration: 300 } : { duration: active.duration },
  })

  return (
    <animated.svg
      id="Logo_FIXED"
      data-name="Logo — FIXED"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 400 400"
      width={width}
      height={height}
      onMouseEnter={() => {
        setActive({ hovered: true, duration: 500 })
      }}
      onMouseLeave={() => {
        setActive({ hovered: false, duration: 500 })
      }}
      style={props}
    >
      <path d="M153.62,301.59c94.34,0,145.94-78.16,145.94-145.94,0-2.22,0-4.43-.15-6.63A104.36,104.36,0,0,0,325,122.47a102.38,102.38,0,0,1-29.46,8.07,51.47,51.47,0,0,0,22.55-28.37,102.79,102.79,0,0,1-32.57,12.45,51.34,51.34,0,0,0-87.41,46.78A145.62,145.62,0,0,1,92.4,107.81a51.33,51.33,0,0,0,15.88,68.47A50.91,50.91,0,0,1,85,169.86c0,.21,0,.43,0,.65a51.31,51.31,0,0,0,41.15,50.28,51.21,51.21,0,0,1-23.16.88,51.35,51.35,0,0,0,47.92,35.62,102.92,102.92,0,0,1-63.7,22A104.41,104.41,0,0,1,75,278.55a145.21,145.21,0,0,0,78.62,23" />
    </animated.svg>
  )
}

export default TwitterIcon
