/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useState } from 'react'
import { animated, useSpring } from 'react-spring'

function GramercyIcon({ width, height, fromColor, toColor, strokeColor }) {
  const [active, setActive] = useState({ hovered: false, duration: 4000 })

  const props = useSpring({
    from: { x: 120, color: fromColor, transform: active.hovered ? 'rotate(0deg)' : 'rotate(180deg)' },
    to: { x: 0, color: toColor, transform: active.hovered ? 'rotate(180deg)' : 'rotate(0deg)' },
    config: active.hovered ? { duration: 300 } : { duration: active.duration },
  })

  const offset = 120

  return (
    <animated.svg
      stroke={strokeColor}
      fill={props.color}
      // stroke-width="1"
      strokeDashoffset={props.x} // the length of the rendered `path` AND length of the gap
      strokeDasharray={offset} // controls where the `dash array` starts i.e. at the start or end of the `path`
      strokeLinecap="round"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 100 100"
      xmlSpace="preserve"
      sx={{
        overflow: 'visible',
      }}
      onMouseEnter={() => {
        setActive({ hovered: true, duration: 500 })
      }}
      onMouseLeave={() => {
        setActive({ hovered: false, duration: 500 })
      }}
      style={props}
    >
      <path
        pathLength="320"
        d="M70.973 2.794A40.364 40.364 0 0056.206 0c-12.598 0-24.621 5.731-33.04 15.74C6.638 24.621-2.251 41.682.492 59.305c2.689 17.271 16.482 31.139 34.374 34.613 7.115 4.51 15.249 6.893 23.546 6.893 9.473 0 18.341-3.072 25.652-8.885C98 80.842 103.29 61.881 97.283 44.647c.801-18.583-9.732-35.368-26.31-41.853m-1.33 3.398c12.534 4.904 21.28 16.302 23.487 29.751a45.137 45.137 0 00-3.489-4.862c-15.522-18.836-36.564-20.87-44.892-20.87-2.638 0-5.13.193-7.207.558-1.787.319-3.549.73-5.273 1.237 10.576-8.239 24.944-10.68 37.374-5.814M4.1 58.742C2.027 45.428 7.271 32.448 17.746 23.761a46.727 46.727 0 00-2.19 4.719c-7.945 19.93-5.264 38.506 7.975 55.213a45.511 45.511 0 003.608 4.049c-12.166-5.234-21.026-16.074-23.039-29m39.559 32.369c-2.488 0-4.984-.219-7.417-.65a41.927 41.927 0 01-9.85-9.031c-12.367-15.61-14.871-32.969-7.446-51.599a43.031 43.031 0 016.565-11.201 44.208 44.208 0 0112.669-4.262c1.305-.231 3.503-.508 6.569-.508 7.808 0 27.54 1.905 42.071 19.545a40.402 40.402 0 016.779 11.784 43.234 43.234 0 01-2.756 12.77c-1.791 4.715-12.174 28.445-40.809 32.678a43.52 43.52 0 01-6.372.475h-.003zm38.132-2.047c-6.657 5.297-14.741 8.096-23.379 8.096-4.66 0-9.266-.822-13.642-2.412a48.705 48.705 0 005.802-.494c30.649-4.535 41.769-29.949 43.687-35a47.764 47.764 0 001.548-4.846c1.547 13.182-3.571 26.35-14.016 34.656"
      ></path>
    </animated.svg>
  )
}

export default GramercyIcon
