/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { Fragment } from 'react'
import { keyframes } from '@emotion/core'

const AnimateWave = keyframes`
  0% {
    d: path("M0,192 C220,100,440,100,660,192 C880,290,1100,290,1320,192 L1320 500 L0 500");
  }
  
  25% {
    d: path("M0,100 C220,100,340,292,660,292 C880,292,1100,200,1320,100 L1320 500 L0 500");
  }
  
  50% {
    d: path("M0,192 C220,290,440,290,660,192 C880,100,1100,100,1320,192 L1320 500 L0 500");
  }
  
  75% {
    d: path("M0,292 C220,292,440,100,660,100 C880,100,1100,292,1320,292 L1320 500 L0 500");
  }
  
  100% {
    d: path("M0,192 C220,100,440,100,660,192 C880,290,1100,290,1320,192 L1320 500 L0 500");
  }
`

const WaveFooter = () => {
  const context = useThemeUI()
  const { theme } = context
  const waveColor = theme.colors.waveFooter

  return (
    <Fragment>
      <div sx={{ display: 'block' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1240 320"
          // viewBox="0 0 1440 320"
          sx={{
            display: 'inline-block',
            verticalAlign: 'middle',
            '&>path': {
              '@media (prefers-reduced-motion: no-preference)': { animation: `${AnimateWave} 45000ms` },
              animation: `undefined`,
              animationTimingFunction: 'cubic-bezier(.67,.24,.36,1) forwards',
              animationIterationCount: 'infinite',
              '&:nth-of-type(2)': {
                // animationDelay: '1s',
              },
              '&:nth-of-type(3)': {
                // animationDelay: '3s',
                // animationPlayState: 'paused',
              },
              '&:nth-of-type(4)': {
                // animationPlayState: 'paused',
                animationDelay: '4s',
              },
            },
          }}
        >
          <path
            fillOpacity="1"
            d="
           M0,192
           C220,100,440,100,660,192
           C880,290,1100,290,1320,192
           L1320 500
           L0 500
           "
            fill={waveColor}
          />
          <path
            fillOpacity="0.75"
            d="
           M0,192
           C220,100,440,100,660,192
           C880,290,1100,290,1320,192
           L1320 500
           L0 500
           "
            fill={waveColor}
          />
          <path
            fillOpacity="0.75"
            d="
           M0,192
           C220,100,440,100,660,192
           C880,290,1100,290,1320,192
           L1320 500
           L0 500
           "
            fill={waveColor}
          />
          <path
            fillOpacity="0.75"
            d="
           M0,192
           C220,100,440,100,660,192
           C880,290,1100,290,1320,192
           L1320 500
           L0 500
           "
            fill={waveColor}
          />
          <path
            fillOpacity="1"
            d="
           M0,192
           C220,100,440,100,660,192
           C880,290,1100,290,1320,192
           L1320 500
           L0 500
           "
            fill={waveColor}
          />
        </svg>
      </div>
    </Fragment>
  )
}

export default WaveFooter
