/** @jsx jsx */
import { jsx, Flex, useThemeUI } from 'theme-ui'
import { Fragment, useState, useLayoutEffect } from 'react'
import { Link } from 'gatsby'
import { useToggle } from '../hooks'
import NavLink from './navLink'
import GramercyIcon from '../assets/gramercyIcon'
import GramercyType from '../assets/gramercyType'
import ColorMode from '../assets/colorMode'
import BurgerButton from './burgerButton'
import MobileNav from './mobileNav'

const Header = ({ children, navLinks, location }) => {
  const context = useThemeUI()
  const { theme } = context

  const themeIconColorFrom = theme.colors.iconFrom
  const themeIconColor = theme.colors.icon

  const headerHeight = '60'

  const [windowSize, setWindowSize] = useState([0, 0])

  const [logoSize, setLogoSize] = useState(75)
  const [logoTypeSize, setLogoTypeSize] = useState(100)

  // Open and close the mobile menu
  const { isToggled: isMobileNavOpen, toggle } = useToggle(false)

  // Detect device window size
  useLayoutEffect(() => {
    function updateSize() {
      setWindowSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  useLayoutEffect(() => {
    const windowWidth = windowSize[0]

    if (windowWidth <= '320') {
      const logoPercent = '0.55'
      const logoTypePercent = '0.55'
      // eslint-disable-next-line
      return setLogoSize(headerHeight * logoPercent), setLogoTypeSize(headerHeight * logoTypePercent)
    }
    if (windowWidth <= '360') {
      const logoPercent = '0.60'
      const logoTypePercent = '0.55'
      // eslint-disable-next-line
      return setLogoSize(headerHeight * logoPercent), setLogoTypeSize(headerHeight * logoTypePercent)
    }
    if (windowWidth <= '375') {
      const logoPercent = '0.75'
      const logoTypePercent = '0.55'
      // eslint-disable-next-line
      return setLogoSize(headerHeight * logoPercent), setLogoTypeSize(headerHeight * logoTypePercent)
    }
    if (windowWidth <= '412') {
      const logoPercent = '0.75'
      const logoTypePercent = '0.55'
      // eslint-disable-next-line
      return setLogoSize(headerHeight * logoPercent), setLogoTypeSize(headerHeight * logoTypePercent)
    }
    if (windowWidth <= '896') {
      const logoPercent = '0.65'
      const logoTypePercent = '0.55'
      // eslint-disable-next-line
      return setLogoSize(headerHeight * logoPercent), setLogoTypeSize(headerHeight * logoTypePercent)
    }
    if (windowWidth <= '1024') {
      const logoPercent = '0.75'
      const logoTypePercent = '0.55'
      // eslint-disable-next-line
      return setLogoSize(headerHeight * logoPercent), setLogoTypeSize(headerHeight * logoTypePercent)
    }
    if (windowWidth <= '1280') {
      const logoPercent = '0.75'
      const logoTypePercent = '0.35'
      // eslint-disable-next-line
      return setLogoSize(headerHeight * logoPercent), setLogoTypeSize(headerHeight * logoTypePercent)
    }
    if (windowWidth > '1280') {
      const logoPercent = '0.65'
      const logoTypePercent = '0.55'
      // eslint-disable-next-line
      return setLogoSize(headerHeight * logoPercent), setLogoTypeSize(headerHeight * logoTypePercent)
    }
  }, [windowSize])

  return (
    <Fragment>
      <header
        sx={{
          variant: 'layout.header',
          width: '100%',
          bg: 'header',
          boxShadow: (theme) => `0px 1px 4px 0px ${theme.colors.muted}`,
        }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            bg: 'inherit',
            height: `${headerHeight}px`,
          }}
        >
          {/* MOBILE Nav*/}
          {windowSize[0] > '414' && windowSize[0] <= '896' && (
            <Fragment>
              <Flex
                sx={{
                  flex: ['1 1 60%'],
                  contain: 'content',
                  height: ['inherit', 'inherit', 'inherit'],
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  px: '2',
                }}
              >
                <Flex sx={{ height: 'inherit', contain: 'content', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                  <Flex sx={{ justifyContent: 'flex-start', alignItems: 'center', maxWidth: '15%', px: '1px', width: '100%' }}>
                    <Link to={'/'} title="Gramercy logo links to Home page" sx={{ display: 'flex', width: '100%' }}>
                      <GramercyIcon width="100%" height={logoSize} fromColor={themeIconColorFrom} toColor={themeIconColor} strokeColor={themeIconColor} />
                    </Link>
                  </Flex>
                  <Flex sx={{ contain: 'content', height: 'inherit', justifyContent: 'flex-start', alignItems: 'center', px: '5px', maxWidth: '180px', width: '100%' }}>
                    <Link to={'/'} title="Gramercy logotype links to Home page" sx={{ display: 'flex', width: '100%' }}>
                      <GramercyType width="100%" height={logoTypeSize} fromColor={themeIconColorFrom} toColor={themeIconColor} strokeColor={themeIconColor} />
                    </Link>
                  </Flex>
                </Flex>
              </Flex>

              <Flex sx={{ flex: ['1 0 40%'], height: 'inherit', p: '2', alignItems: 'center', justifyContent: 'flex-end' }}>
                <BurgerButton aria-label="Opens the main menu" isOpen={isMobileNavOpen} onClickHandler={toggle} sizeOfWindow={windowSize[0]} />
                <Flex sx={{ height: 'inherit', py: '2' }}>
                  <Flex sx={{ px: '1px', maxWidth: '40px' }}>
                    <ColorMode width="100%" height="100%" />
                  </Flex>
                </Flex>
              </Flex>
              <MobileNav nav={navLinks} location={location} isOpen={isMobileNavOpen} />
            </Fragment>
          )}
          {windowSize[0] <= '414' && (
            <Fragment>
              <Flex
                sx={{
                  flex: ['1 1 60%'],
                  contain: 'content',
                  height: ['inherit', 'inherit', 'inherit'],
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  px: '2',
                }}
              >
                <Flex sx={{ height: 'inherit', contain: 'content', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                  <Flex sx={{ contain: 'content', height: 'inherit', justifyContent: 'flex-start', alignItems: 'center', px: '5px', maxWidth: '180px', width: '100%' }}>
                    <Link to={'/'} title="Gramercy logotype links to Home page" sx={{ display: 'flex', width: '100%' }}>
                      <GramercyType width="100%" height={logoTypeSize} fromColor={themeIconColorFrom} toColor={themeIconColor} strokeColor={themeIconColor} />
                    </Link>
                  </Flex>
                </Flex>
              </Flex>

              <Flex sx={{ flex: ['1 0 40%'], height: 'inherit', p: '2', alignItems: 'center', justifyContent: 'flex-end' }}>
                <BurgerButton aria-label="Opens the main menu" isOpen={isMobileNavOpen} onClickHandler={toggle} sizeOfWindow={windowSize[0]} />
                <Flex sx={{ height: 'inherit', py: '2' }}>
                  <Flex sx={{ px: '1px', maxWidth: '40px' }}>
                    <ColorMode width="100%" height="100%" />
                  </Flex>
                </Flex>
              </Flex>
              <MobileNav nav={navLinks} location={location} isOpen={isMobileNavOpen} />
            </Fragment>
          )}
          {/* REGULAR Nav */}
          {windowSize[0] >= '897' && (
            <Fragment>
              <Flex
                sx={{
                  flex: ['1 0 15%', '1 0 20%', '1 0 20%', '1 0 20%'],
                  contain: 'content',
                  height: ['inherit', 'inherit', 'inherit'],
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  px: '2',
                }}
              >
                <Flex sx={{ height: 'inherit', contain: 'content' }}>
                  <Flex sx={{ justifyContent: 'flex-start', alignItems: 'center', maxWidth: '15%', px: '1px' }}>
                    <Link title="Gramercy logo links to Home page" to={'/'} sx={{ display: 'flex' }}>
                      <GramercyIcon width="100%" height={logoSize} fromColor={themeIconColorFrom} toColor={themeIconColor} strokeColor={themeIconColor} />
                    </Link>
                  </Flex>
                  <Flex sx={{ contain: 'content', height: 'inherit', justifyContent: 'flex-start', alignItems: 'center', px: ['8px', '8px', '8px', '8px'], maxWidth: '180px' }}>
                    <GramercyType width="100%" height={logoTypeSize} fromColor={themeIconColorFrom} toColor={themeIconColor} strokeColor={themeIconColor} />
                  </Flex>
                </Flex>
              </Flex>
              <Flex sx={{ flex: ['1 0 20%', '1 0 70%', '1 0 60%', '1 0 60%'], height: 'inherit', justifyContent: 'center', alignItems: 'center' }}>
                <Flex
                  sx={{
                    mx: 4,
                    flexDirection: 'column',
                  }}
                >
                  <NavLink to={'/'} linkname="Services" activepath={(location === '/').toString()} />
                </Flex>
                <Flex
                  sx={{
                    mx: 4,
                    flexDirection: 'column',
                  }}
                >
                  <NavLink to={'/web3/'} linkname="Web3" activepath={(location === '/web3/').toString()} />
                </Flex>
                <Flex
                  sx={{
                    mx: 4,
                    flexDirection: 'column',
                  }}
                >
                  <NavLink to={'/about/'} linkname="About" activepath={(location === '/about/').toString()} />
                </Flex>
                <Flex
                  sx={{
                    mx: 4,
                    flexDirection: 'column',
                  }}
                >
                  <NavLink to={'/work/'} linkname="Work" activepath={(location === '/work/').toString()} />
                </Flex>
                <Flex
                  sx={{
                    mx: 4,
                    flexDirection: 'column',
                  }}
                >
                  <NavLink to={'/contact/'} linkname="Contact" activepath={(location === '/contact/').toString()} />
                </Flex>
              </Flex>

              <Flex sx={{ flex: ['1 0 10%', '1 0 10%', '1 0 20%', '1 0 20%'], height: 'inherit', p: '2', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Flex sx={{ display: 'flex', height: 'inherit', py: '2' }}>
                  <Flex sx={{ px: '1px', pt: '2px', maxWidth: '40px', justifyContent: 'center' }}>
                    <ColorMode width="100%" height="100%" />
                  </Flex>
                </Flex>
              </Flex>
            </Fragment>
          )}
        </Flex>
      </header>
    </Fragment>
  )
}

export default Header
