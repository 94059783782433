/** @jsx jsx */
import { jsx, Flex, useThemeUI } from 'theme-ui'
import { Fragment, useState, useLayoutEffect } from 'react'
import { useSpring, useTrail, animated, config, interpolate } from 'react-spring'
import NavLink from './navLink'

const MobileNav = ({ style, nav, location, isOpen }) => {
  // eslint-disable-next-line
  const [isNavOpen, setIsNavOpen] = useState(false)

  const context = useThemeUI()
  const { theme } = context

  const AnimatedFlex = animated(Flex)

  // Modal Animation
  const mobileNavAnimation = useSpring({
    transform: isOpen ? 'translate3d(0,0,0) scale(1)' : 'translate3d(0,100%,0) scale(1)',
    config: config.default,
  })

  useLayoutEffect(() => {
    setIsNavOpen(isOpen)
    setMenu({
      sc: isOpen ? 1 : 1,
      x: isOpen ? '0%' : '100%',
      y: isOpen ? '0px' : '100px',
      skewX: isOpen ? 0 : 20,
      opacity: isOpen ? 1 : 0,
    })

    // eslint-disable-next-line
  }, [isOpen])

  const [trailMenu, setMenu] = useTrail(nav.length, () => ({
    config: {
      tension: 210,
      friction: 20,
    },
    opacity: 1,
    sc: 0.9,
    x: '0%',
    y: '0px',
    skewX: 0,
    from: {
      opacity: 0,
      sc: 0.8,
      x: '100%',
      y: '100px',
      skewX: 20,
    },
  }))

  return (
    <Fragment>
      <animated.div
        style={mobileNavAnimation}
        sx={{
          bg: 'primary',
          position: 'fixed',
          left: '0',
          right: '0',
          top: '60px',
          bottom: '0',
          mt: 4,
          py: 3,
          zIndex: '10',
          overflowY: 'auto',
        }}
      >
        <Flex
          my="3"
          mx="3"
          px="4"
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Fragment>
            {trailMenu.map(({ sc, x, y, skewX, ...props }, i) => {
              return (
                <Fragment key={i}>
                  <AnimatedFlex
                    mt="3"
                    mb="3"
                    py="2"
                    sx={{
                      color: `${theme.colors.background}`,
                    }}
                    key={i}
                    style={{
                      ...props,
                      transform: interpolate([sc, x, y, skewX], (sc, x, y, skewX) => `scale(${sc}) translate(${x}, ${y}) skewX(${skewX}deg)`),
                    }}
                  >
                    <NavLink to={nav[i].link} linkname={nav[i].name} activepath={(location === nav[i].link).toString()} underlinecolor={theme.colors.accent} linkvariant="links.navMobile" />
                  </AnimatedFlex>
                </Fragment>
              )
            })}
          </Fragment>
        </Flex>
      </animated.div>
    </Fragment>
  )
}

export default MobileNav
