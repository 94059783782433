import { graphql, useStaticQuery } from 'gatsby'

const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query USE_SITE_METADATA_QUERY {
      site {
        siteMetadata {
          siteTitle
          siteHeadline
          siteUrl
          siteDescription
          siteKeywords
          siteLanguage
          siteImage
          author
          menuLinks {
            id
            link
            name
          }
        }
      }
    }
  `)

  return data.site.siteMetadata
}

export default useSiteMetadata
