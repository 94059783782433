/** @jsx jsx */
import { jsx, useColorMode, useThemeUI } from 'theme-ui'
import { useState, Fragment, useEffect } from 'react'
import { animated, useSpring, config, useTransition } from 'react-spring'
import { isMobile } from 'react-device-detect'
import { useToggle } from '../hooks'

const bursts = [
  {
    path: 'M12.7 50c0-2.3-1.9-4.2-4.2-4.2H4.2C1.9 45.8 0 47.7 0 50c0 2.3 1.9 4.2 4.2 4.2h4.4C10.9 54.2 12.7 52.3 12.7 50z',
    color: 'yellow',
  },
  {
    path: 'M95.8 45.8h-4.4c-2.3 0-4.2 1.9-4.2 4.2 0 2.3 1.9 4.2 4.2 4.2h4.4c2.3 0 4.2-1.9 4.2-4.2C100 47.7 98.1 45.8 95.8 45.8z',
    color: 'yellow',
  },
  {
    path: 'M25.7 16.2c0.8 1.3 2.2 2.1 3.6 2.1 0.7 0 1.4-0.2 2.1-0.6 2-1.2 2.7-3.7 1.5-5.7l-2.2-3.8c-1.2-2-3.7-2.7-5.7-1.5 -2 1.2-2.7 3.7-1.5 5.7L25.7 16.2z',
    color: 'yellow',
  },
  {
    path: 'M74.3 83.8c-1.2-2-3.7-2.7-5.7-1.5 -2 1.2-2.7 3.7-1.5 5.7l2.2 3.8c0.8 1.3 2.2 2.1 3.6 2.1 0.7 0 1.4-0.2 2.1-0.6 2-1.2 2.7-3.7 1.5-5.7L74.3 83.8z',
    color: 'yellow',
  },

  {
    path: 'M31.4 82.3c-2-1.2-4.6-0.5-5.7 1.5l-2.2 3.8c-1.2 2-0.5 4.6 1.5 5.7 0.7 0.4 1.4 0.6 2.1 0.6 1.4 0 2.8-0.8 3.6-2.1l2.2-3.8C34 86 33.4 83.4 31.4 82.3z',
    color: 'yellow',
  },
  {
    path: 'M68.6 17.7c0.7 0.4 1.4 0.6 2.1 0.6 1.4 0 2.8-0.7 3.6-2.1l2.2-3.8c1.2-2 0.5-4.6-1.5-5.7 -2-1.2-4.6-0.5-5.7 1.5l-2.2 3.8C65.9 14 66.6 16.6 68.6 17.7z',
    color: 'yellow',
  },
  // {
  //   path: 'M45.8 4.2v4.4c0 2.3 1.9 4.2 4.2 4.2 2.3 0 4.2-1.9 4.2-4.2V4.2C54.2 1.9 52.3 0 50 0 47.7 0 45.8 1.9 45.8 4.2z',
  //   color: 'red',
  // },
  // {
  //   path: 'M54.2 95.8v-4.4c0-2.3-1.9-4.2-4.2-4.2 -2.3 0-4.2 1.9-4.2 4.2v4.4c0 2.3 1.9 4.2 4.2 4.2C52.3 100 54.2 98.1 54.2 95.8z',
  //   color: 'red',
  // },
  // {
  //   path: 'M12 67.1l-3.8 2.2C6.2 70.4 5.5 73 6.7 75c0.8 1.3 2.2 2.1 3.6 2.1 0.7 0 1.4-0.2 2.1-0.6l3.8-2.2c2-1.2 2.7-3.7 1.5-5.7C16.6 66.6 14 66 12 67.1z',
  //   color: 'red',
  // },
  // {
  //   path: 'M87.6 23.5l-3.8 2.2c-2 1.2-2.7 3.7-1.5 5.7 0.8 1.3 2.2 2.1 3.6 2.1 0.7 0 1.4-0.2 2.1-0.6l3.8-2.2c2-1.2 2.7-3.7 1.5-5.7C92.1 23 89.6 22.3 87.6 23.5z',
  //   color: 'red',
  // },
  // {
  //   path: 'M8.2 30.7l3.8 2.2c0.7 0.4 1.4 0.6 2.1 0.6 1.4 0 2.8-0.7 3.6-2.1 1.2-2 0.5-4.6-1.5-5.7l-3.8-2.2c-2-1.2-4.6-0.5-5.7 1.5C5.5 27 6.2 29.6 8.2 30.7z',
  //   color: 'red',
  // },
  // {
  //   path: 'M82.3 68.6c-1.2 2-0.5 4.6 1.5 5.7l3.8 2.2c0.7 0.4 1.4 0.6 2.1 0.6 1.4 0 2.8-0.7 3.6-2.1 1.2-2 0.5-4.6-1.5-5.7l-3.8-2.2C86 66 83.4 66.6 82.3 68.6z',
  //   color: 'red',
  // },
]

function ColorMode({ width, height }) {
  const [wasToggleClicked, setToggleClicked] = useState(false)
  const { isToggled, toggle } = useToggle(false)
  const [colorMode, setColorMode] = useColorMode()
  // const newColorMode = colorMode === 'light' || colorMode === 'default' ? 'dark' : 'light'
  const context = useThemeUI()
  const { theme } = context

  const themeBackgroundColor = theme.colors.header

  useEffect(() => {
    const buttonClicked = wasToggleClicked

    if (buttonClicked) {
      const newColorMode = colorMode === 'light' || colorMode === 'default' ? 'dark' : 'light'
      setColorMode(newColorMode)
    }
    return function cleanup() {
      setToggleClicked(false)
    }
  })

  // for the sun to moon transition
  const props = useSpring({
    from: colorMode === 'light' || colorMode === 'default' ? { cx: '23.5', cy: '35.5', r: '25' } : { cx: '-35.5', cy: '35.5', r: '25' },
    to: colorMode === 'light' || colorMode === 'default' ? { cx: '-35.5', cy: '35.5', r: '25' } : { cx: '23.5', cy: '35.5', r: '25' },
    config: colorMode === 'light' || colorMode === 'default' ? config.molasses : config.slow,
  })

  const hoverAnimation = useSpring({
    opacity: isToggled ? 1 : 1,
  })

  const backgroundAnimation = useSpring({
    color: colorMode === 'light' || colorMode === 'default' ? themeBackgroundColor : themeBackgroundColor,
    config: { duration: 0 },
  })

  const starAnimation = useSpring({
    from: colorMode === 'light' || colorMode === 'default' ? { fill: 'yellow', opacity: 0 } : { fill: 'white', opacity: 1 },
    to: colorMode === 'light' || colorMode === 'default' ? { fill: 'yellow', opacity: 0 } : { fill: 'white', opacity: 1 },
  })

  const transition = useTransition(colorMode === 'light' || colorMode === 'default' ? bursts : [], (item) => item.path, {
    trail: 900 / bursts.length,
    from: {
      opacity: 0,
      fill: themeBackgroundColor,
    },
    enter: {
      opacity: 1,
      fill: 'orange',
      // stroke: 'red',
    },
    leave: {
      opacity: 0,
      fill: themeBackgroundColor,
    },
  })

  return (
    <Fragment>
      <button
        aria-label="Change the color mode"
        sx={{ py: 0, px: 0, border: 'none', bg: 'inherit', cursor: 'pointer' }}
        onClick={() => {
          setToggleClicked(true)
        }}
        onMouseEnter={() => {
          toggle(true)
        }}
        onMouseLeave={() => {
          toggle(false)
        }}
      >
        <div sx={{ display: 'flex' }}>
          <animated.svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 73 73" x="100">
            <defs>
              <radialGradient id="RadialGrad" fx="50%" fy="50%" r="65%" spreadMethod="pad">
                {colorMode === 'light' || colorMode === 'default' ? (
                  <Fragment>
                    <stop offset="10%" stopColor="orange" stopOpacity="1" />
                    <stop offset="100%" stopColor="yellow" stopOpacity="1" />
                  </Fragment>
                ) : (
                  <Fragment>
                    <stop offset="0%" stopColor="#cbd5e0" stopOpacity="1" />
                    <stop offset="100%" stopColor="#edf2f7" stopOpacity="1" />
                  </Fragment>
                )}
              </radialGradient>
            </defs>
            <animated.rect width="100%" height="100%" fill={backgroundAnimation.color} />

            {/* Sunburst */}
            <animated.g style={hoverAnimation}>
              <animateTransform attributeName="transform" type="rotate" from="0 35.5 35.5" to="360 35.5 35.5" begin="0s" dur="60s" repeatCount="indefinite" />
              {colorMode === ('light' || 'default') ? (
                <Fragment>
                  {bursts.map((item) => (
                    <path transform="scale(.71)" d={item.path} key={item.path} fill="red" />
                  ))}
                </Fragment>
              ) : (
                <Fragment>
                  {transition.map(({ item, key, props }) => (
                    <animated.path transform="scale(.71)" d={item.path} key={key} style={props} />
                  ))}
                </Fragment>
              )}
              )}
            </animated.g>

            {/* Sun and Moon */}
            {isMobile ? (
              <animated.g transform="rotate(140 35.5 35.5)" style={hoverAnimation}>
                <animated.circle cx="35.5" cy="35.5" r="25" stroke="none" fill="url(#RadialGrad)" />
                {/* Cresent moon - this is a separate cricle that moves over (and back) the circle above - like an eclipse */}
                {colorMode === ('light' || 'default') && <animated.circle style={props} stroke="none" fill={backgroundAnimation.color} />}
                {colorMode === 'dark' && <animated.circle cx="23.5" cy="35.5" r="25" stroke="none" fill={backgroundAnimation.color} />}
              </animated.g>
            ) : (
              <animated.g transform="rotate(140 35.5 35.5)" style={hoverAnimation}>
                <animated.circle cx="35.5" cy="35.5" r="25" stroke="none" fill="url(#RadialGrad)" />
                {/* Cresent moon - this is a separate cricle that moves over (and back) the circle above - like an eclipse */}
                {colorMode === ('light' || 'default') && <animated.circle style={props} stroke="none" fill={backgroundAnimation.color} />}
                {colorMode === 'dark' && <animated.circle cx="23.5" cy="35.5" r="25" stroke="none" fill={backgroundAnimation.color} />}
                {/* {colorMode === ('light' || 'default') && <animated.circle style={props} stroke="none" fill={backgroundAnimation.color} />}
                {colorMode === 'dark' && <animated.circle style={props} stroke="none" fill={backgroundAnimation.color} />} */}
              </animated.g>
            )}

            {/* Stars */}
            <animated.g style={hoverAnimation}>
              <animated.g style={starAnimation} transform="translate(52,7), scale(0.6), rotate(90)">
                <animated.path id="star" d="M12,1.776l3.286,6.779l7.464,1.032l-5.433,5.221l1.326,7.417L12,18.67l-6.645,3.555l1.327-7.417L1.25,9.587l7.464-1.032L12,1.776z" />
              </animated.g>
              <animated.g style={starAnimation} transform="translate(58,27), scale(0.4), rotate(90)">
                <animated.path id="star" d="M12,1.776l3.286,6.779l7.464,1.032l-5.433,5.221l1.326,7.417L12,18.67l-6.645,3.555l1.327-7.417L1.25,9.587l7.464-1.032L12,1.776z" />
              </animated.g>
            </animated.g>
          </animated.svg>
        </div>
      </button>
    </Fragment>
  )
}

export default ColorMode
