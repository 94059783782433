import { useState } from 'react'

export const useToggle = (initial) => {
  const [isToggled, setToggle] = useState(initial)
  const toggle = () => setToggle((prevState) => !prevState)
  // when using in this hook in a component, we would have to return all properties just to access the third property: the 'toggle' function
  // return [isToggled, setToggle, toggle]
  // using an Array syntax we can of course rename each property in our component if we want to
  // Returning them as an object below, allows us to return any property we want as 'named' functions (but of course we can't now rename these when we call them from our components, unless we 'rename' variables eg: 'setToggle: newSetToggle')
  return { isToggled, setToggle, toggle }
}
