// Big props to Josh W. Comeau for the shot of whimsical inspiration
/** @jsx jsx */
import { jsx } from 'theme-ui'
import { keyframes } from '@emotion/core'
import { range } from '../../utils/svg-options'
import React, { Fragment } from 'react'

const WiggleyLine = React.memo(({ width, height, squiggleWidth, phase, color = '#be4b52' }) => {
  const numOfSquiggles = Math.round(width / squiggleWidth)
  const roundedSquiggleWidth = width / numOfSquiggles

  const linePositionY = height / 2

  const initialPoint = { x: 0, y: linePositionY }

  const instructions = range(0, numOfSquiggles).reduce((acc, i) => {
    const lastPointX = i * roundedSquiggleWidth

    const curveYMin = -15
    const curveYMax = 15

    const controlPointX = lastPointX + roundedSquiggleWidth / 2

    const controlPointY = linePositionY + Math.floor(Math.random() * (curveYMax - curveYMin)) + curveYMin

    const x = lastPointX + roundedSquiggleWidth
    const y = linePositionY

    const instruction = `Q ${controlPointX},${controlPointY} ${x},${y}`

    return `${acc} ${instruction}`
  }, `M ${initialPoint.x},${initialPoint.y}`)

  return (
    <Fragment>
      <div style={{ display: 'grid', overflow: 'visible' }}>
        <svg width={width} height={height}>
          <path
            sx={{
              strokeDasharray: `${width + 20} ${width + 20}`, // the length of the rendered `path` AND length of the gap
              strokeDashoffset: `${width}`, // controls where the `dash array` starts i.e. at the start or end of the `path`
              animation: `${dash} 1.5s 0s forwards;`,
            }}
            d={instructions}
            fill="none"
            stroke={color}
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </Fragment>
  )
})

WiggleyLine.defaultProps = {
  width: 55,
  height: 10,
  squiggleWidth: 5,
  phase: 1,
}

const dash = keyframes`
  from {
    stroke-dashoffset: {width+20};
  }
  to {
    stroke-dashoffset: 0;
  }
`

export default WiggleyLine
