/** @jsx jsx */
import { jsx, Styled, Box, Flex } from 'theme-ui'
import { Fragment, useState } from 'react'
// import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import NavLink from './navLink'
import GramercyIcon from '../assets/gramercyIcon'
import TwitterIcon from '../assets/twitterIcon'
import LinkedInIcon from '../assets/linkedInIcon'
import WaveFooter from './waveFooter'

const Footer = ({ children }) => {
  // const { pathname } = useLocation()
  // const context = useThemeUI()
  // const { theme } = context

  // const themeIconColor = theme.colors.icon

  const handleOnClick = () => {
    navigate('/')
  }

  return (
    <Fragment>
      <div sx={{ position: 'relative', flex: 'none', backgroundColor: 'inherit' }}>
        <WaveFooter />
      </div>
      <footer
        sx={{
          width: '100%',
          variant: 'layout.footer',
          bg: 'footer',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateRows: 'repeat(5, 32px)',
            gridTemplateColumns: ['50% 50%', '25% 50% 25%', '25% 50% 25%', '25% 50% 25%'],
            gridAutoFlow: 'column',
            px: [1, 1, 2, 2],
            py: 2,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <NavLink to={'/'} linkname="Services" activepath={null} linkvariant="links.navFooter" />
          </Box>
          <Box>
            <NavLink to={'/web3/'} linkname="Web3" activepath={null} linkvariant="links.navFooter" />
          </Box>
          <Box>
            <NavLink to={'/about/'} linkname="About" activepath={null} linkvariant="links.navFooter" />
          </Box>
          <Box>
            <NavLink to={'/work/'} linkname="Work" activepath={null} linkvariant="links.navFooter" />
          </Box>
          <Box>
            <NavLink to={'/contact/'} linkname="Contact" activepath={null} linkvariant="links.navFooter" />
          </Box>

          <Flex sx={{ gridRow: '1 / span 5', contain: 'content', p: '1', justifyContent: 'center', alignItems: 'center', height: '100%', display: ['none', 'flex', 'flex', 'flex'] }}>
            <Flex sx={{ width: ['40px', '60px', '70px', '80px'], height: 'inherit' }}>
              <button aria-label="Gramercy logo link to Home page" onClick={handleOnClick} sx={{ bg: 'inherit', border: 'none', height: 'inherit', p: '0', cursor: 'pointer' }}>
                <GramercyIcon width="100%" height="100%" fromColor="#404040" toColor="#ffffff" strokeColor="#ffffff" />
              </button>
            </Flex>
          </Flex>

          <Flex sx={{ justifyContent: 'flex-end', height: '100%' }}>
            <Flex sx={{ width: '40px', height: 'inherit' }}>
              <a href="https://twitter.com/gramercystudios" title="Twitter">
                <TwitterIcon width="100%" height="100%" footer="true" />
              </a>
            </Flex>
            <Flex sx={{ width: '40px', height: 'inherit' }}>
              <a href="https://www.linkedin.com/company/gramercy-studios" title="LinkedIn">
                <LinkedInIcon width="100%" height="100%" footer="true" />
              </a>
            </Flex>
          </Flex>
        </Box>
        {/* Change placement of Gramercy logo on phones */}
        <Flex sx={{ contain: 'content', p: '4', justifyContent: 'center', alignItems: 'center', height: '100%', display: ['flex', 'none', 'none', 'none'] }}>
          <Flex sx={{ width: ['40px'], height: 'inherit' }}>
            <button aria-label="Gramercy logo link to Home page" onClick={handleOnClick} sx={{ bg: 'inherit', border: 'none', height: 'inherit', p: '0', cursor: 'pointer' }}>
              <GramercyIcon width="100%" height="100%" fromColor="#404040" toColor="#ffffff" strokeColor="#ffffff" />
            </button>
          </Flex>
        </Flex>
        <Box
          sx={{
            display: 'flex',
            justifyContent: ['center', 'center', 'flex-end', 'flex-end'],
            px: 2,
            py: 2,
            textAlign: 'center',
          }}
        >
          <Box sx={{ mx: 1 }} />
          <Styled.p sx={{ fontSize: [0, 0, 1], color: 'footerNav' }}>© {new Date().getFullYear()} Gramercy Studios Pty. Ltd. All rights reserved.</Styled.p>
          <Box sx={{ mx: 1 }} />
        </Box>
      </footer>
    </Fragment>
  )
}

export default Footer
