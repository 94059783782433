/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useResizeObserver } from '../hooks'
import { Link } from 'gatsby'
import { Fragment } from 'react'
import WiggleyLine from './wiggleyLine'

// Gatsby Link gives us features such as: preloading
// we use it to replace `a` tags when linking internally within the app

const NavLink = (props) => {
  // const [isHovered, bind] = useHover()

  const [ref, entry] = useResizeObserver()

  const isActive = props.activepath

  const linkName = props.linkname

  const activeUnderlineColor = props.underlinecolor

  const navVariant = props.linkvariant || 'links.nav'

  return (
    <Fragment>
      <Link
        {...props}
        // {...bind}
        ref={ref}
        activeClassName="active"
        sx={{
          variant: navVariant,
        }}
      >
        {linkName}

        {isActive === 'true' && (
          <div
            // {...bind}
            sx={{
              background: 'rgba(100,100,200,0)',
              width: '0px',
              height: '5px',
              position: 'absolute',
            }}
          >
            <WiggleyLine width={entry.width} height={25} squiggleWidth={16} phase={4} color={activeUnderlineColor} />
          </div>
        )}
      </Link>
    </Fragment>
  )
}

export default NavLink
