/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useState } from 'react'
import { animated, useSpring } from 'react-spring'

function LinkedInIcon({ width, height, footer }) {
  const [active, setActive] = useState({ hovered: false, duration: 4000 })

  const fromColorFill = footer ? '#ffffff' : '#1da1f2'
  const toColorFill = footer ? '#1da1f2' : '#be4b52'

  const props = useSpring({
    from: active.hovered ? { fill: fromColorFill, transform: 'rotate(0deg)' } : { fill: fromColorFill, transform: 'rotate(0deg)' },
    to: active.hovered ? { fill: toColorFill, transform: 'rotate(360deg)' } : { fill: fromColorFill, transform: 'rotate(0deg)' },
    config: active.hovered ? { duration: 300 } : { duration: active.duration },
  })

  return (
    <animated.svg
      id="Logo_FIXED"
      data-name="Logo — FIXED"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      width={width}
      height={height}
      onMouseEnter={() => {
        setActive({ hovered: true, duration: 500 })
      }}
      onMouseLeave={() => {
        setActive({ hovered: false, duration: 500 })
      }}
      style={props}
    >
      <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
    </animated.svg>
  )
}

export default LinkedInIcon
